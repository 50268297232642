<template>
  <div>
    <div v-bind="$attrs" class="flex items-center gap-3 sm:flex-row flex-col">
      <div class="w-full h-full relative">
        <div absolute bottom-0 translate-y-full left-0 w-full class="z-9999">
          <SearchContent
            ref="searchContentRef"
            v-model:searchValue="keyword"
            :content-list="searchContentList"
            @add="handleSearchAdd"
            @get-content-data="handleGetContentData"
            class="shadow-md"
          />
        </div>

        <el-input
          ref="searchInputRef"
          v-model.trim="keyword"
          clearable
          placeholder="請輸入車牌號碼/客戶暱稱/客戶電話/引擎號碼/車身號碼"
          @keydown.enter="searchSubmit()"
          @compositionstart="handleCompositionStart"
          @compositionend="handleCompositionEnd"
          class="text-uppercase h-full w-full"
          id="search_plate_number"
        />
      </div>
      <el-button type="primary" @click.prevent="searchSubmit()" class="h-full sm:w-auto w-full"
        >立即搜尋</el-button
      >
    </div>

    <!-- 新增車籍資料 -->
    <DialogFixAddQuick
      :search-value="keyword"
      :isOpen="visibleDialog.addPlateNumber"
      @go-close="visibleDialog.addPlateNumber = false"
    />
  </div>
</template>

<script setup>
import { search } from '@/api/vechicle'
import { onClickOutside } from '@vueuse/core'
import { getSearchKeyword, setSearchKeyword } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import { useMagicKeys, useTimeoutFn } from '@vueuse/core'

import SearchContent from '@/components/Dashboard/DashboardSearchContent.vue'
import DialogFixAddQuick from '@/components/branch/DialogFixAddQuick.vue'
import { unref } from 'vue'

const router = useRouter()
const emit = defineEmits(['searchResponse'])

// 關鍵字值
const keyword = defineModel({
  type: String,
  default: ''
})

// 搜尋彈窗實例
const searchContentRef = ref()
// 搜尋輸入框實例
const searchInputRef = ref()

const visibleDialog = ref({
  addPlateNumber: false
})

// #region 搜尋結果 - 開始搜尋
const PAGE_ITEMS = 10 // 預設搜尋每頁筆數
const searchContentList = ref([]) //搜尋結果

const searchResults = async () => {
  searchContentRef.value.handleSearchResults(false) // 隱藏搜尋結果

  try {
    const keywordUnref = unref(keyword)
    const pageUnref = null
    // console.log('searchResults ===>', keywordUnref)
    if (keywordUnref?.length <= 0) {
      return
    }

    const { data = {}, sysCode = 0 } = await search(keywordUnref, pageUnref, PAGE_ITEMS)
    // console.log('searchResults ===>', data, sysCode)
    if (Number(sysCode) === 200) {
      const { vehicle = [], contacts = [], full_list = [] } = data
      const mergeData = full_list

      // 如果有需要針對搜尋結果做特殊處理, 可在這裡調整

      searchContentList.value = mergeData
      // 將搜尋紀錄的關鍵字存入 localStorage
      setSearchKeywordToStorage(keywordUnref)

      emit('searchResponse', {
        success: true
      })
    }
  } catch (error) {
    console.error('searchResults Error===>', error)
    ElMessage.error('搜尋失敗')

    emit('searchResponse', {
      success: false
    })
  } finally {
    if (searchContentRef.value) searchContentRef.value.handleSearchResults(true) // 顯示搜尋結果
  }
}
// 延遲搜尋
const { isPending, start: startSearch, stop: stopSearch } = useTimeoutFn(searchResults, 500, {
  immediate: false
})

// #endregion

// #region 將搜尋紀錄的關鍵字存入 localStorage
function setSearchKeywordToStorage(value) {
  // 取得 localStorage 的搜尋紀錄
  let currentKeywordList = getSearchKeyword()
  if (!currentKeywordList) {
    // 如果不存在則初始化為空陣列
    setSearchKeyword([])
    currentKeywordList = []
  }

  // 將搜尋資料規格化，確保沒空白
  const formattedKeyword = value.replace(/\s+/g, '').toUpperCase()

  // 如果搜尋紀錄已經有這個關鍵字，就不再新增
  if (currentKeywordList.includes(formattedKeyword)) {
    return
  } else {
    // 沒有重複的關鍵字，新增搜尋紀錄
    currentKeywordList.unshift(formattedKeyword)
    // 如果搜尋紀錄超過三筆，則刪除最舊一筆
    if (currentKeywordList.length > 3) {
      currentKeywordList.pop()
    }
    // 將搜尋紀錄存入 localStorage
    setSearchKeyword(currentKeywordList)
  }
}
// #endregion

// #region 搜尋彈窗
const isComposing = ref(false) // 是否正在輸入注音中
// 輸入法事件
const handleCompositionStart = () => {
  isComposing.value = true
}
const handleCompositionEnd = () => {
  isComposing.value = false
}

const handleContentHide = () => {
  if (searchContentRef.value) searchContentRef.value.handleHide()
  searchContentList.value = [] // 清空搜尋結果
}

// 點擊外部關閉搜尋內容
onClickOutside(searchContentRef, () => {
  handleContentHide()
})

// 搜尋內容 - 新增
const handleSearchAdd = () => {
  handleContentHide()
  visibleDialog.value.addPlateNumber = true
}

// 搜尋內容 - 取得資料
const handleGetContentData = async (data) => {
  // console.log('handleGetContentData ===>', data)
  if (Object.keys(data).length === 0) return
  // keyword.value = data.plate_number
  searchInputRef.value.blur()
  keyword.value = ''

  handleContentHide()
}

// 送出搜尋
const searchSubmit = async (customSearchParams = {}) => {
  // console.log('searchSubmit ===>', customSearchParams)
  const searched = customSearchParams?.keyword

  searched && (keyword.value = searched)

  if (keyword.value.trim().length === 0 && !isComposing.value) {
    ElMessage.error('請輸入關鍵字')
    return
  }

  keyword.value = keyword.value.trim().toUpperCase()

  try {
    if (searchContentRef.value) {
      await searchContentRef.value.handleShow()
    }
    await startSearch()
  } catch (error) {
    console.error('searchSubmit ===>', error)
  }
}

// 當按下 ESC 鍵時
const { escape } = useMagicKeys()
watch(escape, () => {
  handleContentHide()
})

// 當keyword超過4個字，顯示搜尋內容
watch(keyword, (newVal, oldVal) => {
  // 避免輸入法輸入時觸發
  if (isComposing.value) return

  if (newVal.length >= 5) {
    searchContentList.value = [] // 清空搜尋結果

    searchSubmit()
  } else {
    stopSearch()
  }
})
// #endregion

defineExpose({
  searchSubmit
})
</script>

<style></style>
