import request from '@/utils/axiosReq'

export function formItems() {
  return {
    table: [
      {
        prop: 'branchId',
        placeholder: '分店ID',
        label: '分店ID',
        disabled: true
      },
      {
        prop: 'branchName',
        placeholder: '分店名稱',
        label: '分店名稱'
      },
      {
        prop: 'branchManagerId',
        placeholder: '店長ID',
        label: '店長ID',
        type: 'select'
      },
      {
        prop: 'branchStatus',
        placeholder: '分店狀態', //0: disable, 1: enable
        label: '分店狀態', //0: disable, 1: enabl
        type: 'select',
        options: [
          {
            value: 0,
            label: 'disabled'
          },
          {
            value: 1,
            label: 'enable'
          }
        ]
      },
      {
        prop: 'branchAddress',
        placeholder: '分店地址',
        label: '分店地址'
      },
      {
        prop: 'branchTaxId',
        placeholder: '分店統編',
        label: '分店統編'
      }
    ],
    add: [
      {
        prop: 'headquarterId',
        placeholder: '總店ID',
        label: '總店ID',
        disabled: true,
        visable: false
      },
      {
        prop: 'branchId',
        placeholder: '分店ID',
        label: '分店ID',
        disabled: true
      },
      {
        prop: 'branchName',
        placeholder: '分店名稱',
        label: '分店名稱'
      },
      {
        prop: 'branchManagerId',
        placeholder: '店長ID',
        label: '店長ID',
        type: 'select'
      },
      {
        prop: 'branchStatus',
        placeholder: '分店狀態', //0: disable, 1: enable
        label: '分店狀態', //0: disable, 1: enabl
        type: 'select',
        options: [
          {
            value: 0,
            label: 'disabled'
          },
          {
            value: 1,
            label: 'enable'
          }
        ]
      },
      {
        prop: 'branchAddress',
        placeholder: '分店地址',
        label: '分店地址'
      },
      {
        prop: 'branchTaxId',
        placeholder: '分店統編',
        label: '分店統編'
      }
    ],
    edit: [
      {
        prop: 'headquarterId',
        placeholder: '總店ID',
        label: '總店ID',
        disabled: true
      },
      {
        prop: 'branchId',
        placeholder: '分店ID',
        label: '分店ID',
        disabled: true
      },
      {
        prop: 'branchName',
        placeholder: '分店名稱',
        label: '分店名稱'
      },
      {
        prop: 'branchAddress',
        placeholder: '分店地址',
        label: '分店地址'
      },
      {
        prop: 'branchTaxId',
        placeholder: '分店統編',
        label: '分店統編'
      },
      {
        prop: 'branchManagerId',
        placeholder: '店長ID',
        label: '店長ID',
        type: 'select'
      },
      {
        prop: 'branchStatus',
        placeholder: '分店狀態', //0: disable, 1: enable
        label: '分店狀態', //0: disable, 1: enabl
        type: 'select',
        options: [
          {
            value: 0,
            label: '關閉'
          },
          {
            value: 1,
            label: '開啟'
          }
        ]
      }
    ],
    dialog: [
      {
        name: 'add',
        title: '增加'
      },
      {
        name: 'edit',
        title: '編輯分店'
      }
    ],
    headquarter: [
      {
        prop: 'headquarterName',
        placeholder: '總部名稱',
        label: '總部名稱'
      },
      {
        prop: 'planName',
        placeholder: '方案名稱',
        label: '方案名稱'
      },
      {
        prop: 'headquarterAccount',
        placeholder: '總部管理員',
        label: '總部管理員'
      },
      {
        prop: 'managerName',
        placeholder: '系統管理員',
        label: '系統管理員'
      },
      {
        prop: 'headquarterContactName',
        placeholder: '取車人姓名',
        label: '取車人姓名'
      },
      {
        prop: 'headquarterContactEmail',
        placeholder: '取車人電子郵件',
        label: '取車人電子郵件',
        type: 'email'
      },
      {
        prop: 'headquarterContactPhone',
        placeholder: '取車人電話',
        label: '取車人電話'
      },
      {
        prop: 'headquarterPrincipalName',
        placeholder: '負責人姓名',
        label: '負責人姓名'
      }
    ],
    plan_setting: [
      {
        prop: 'planId',
        placeholder: '方案編號',
        label: '方案編號',
        type: 'select'
      },
      {
        prop: 'managerId',
        placeholder: '管理者編號',
        label: '管理者編號'
      },
      {
        prop: 'planStartDate',
        placeholder: '方案開始日期',
        label: '方案開始日期',
        type: 'date',
        directions: '如果是空值，就是立即生效'
      },
      {
        prop: 'planEndDate',
        placeholder: '方案結束日期，',
        label: '方案結束日期',
        type: 'date',
        directions: '如果是空值就為方案的結束日期'
      },
      {
        prop: 'planAuthCountLimit',
        placeholder: '授權數量上限',
        label: '授權數量上限',
        directions: '如果是空值就為方案的數量上限'
      }
    ],
    headquarter_add: [
      {
        prop: 'headquarterTaxId',
        placeholder: '統一編號',
        label: '統一編號',
        type: 'number'
      },
      {
        prop: 'headquarterName',
        placeholder: '總部名稱',
        label: '總部名稱'
      },
      {
        prop: 'headquarterZip',
        placeholder: '郵遞區號',
        label: '郵遞區號'
      },
      {
        prop: 'headquarterAddress',
        placeholder: '地址',
        label: '地址'
      },
      {
        prop: 'headquarterPhone',
        placeholder: '電話',
        label: '電話'
      },
      {
        prop: 'headquarterEmail',
        placeholder: '電子郵件',
        label: '電子郵件',
        type: 'email'
      },
      {
        prop: 'headquarterPrincipalId',
        placeholder: '負責人身分證字號',
        label: '負責人身分證字號'
      },
      {
        prop: 'headquarterPrincipalName',
        placeholder: '負責人姓名',
        label: '負責人姓名'
      },
      {
        prop: 'headquarterPrincipalBirthday',
        placeholder: '負責人生日',
        label: '負責人生日',
        type: 'date'
      },
      {
        prop: 'headquarterPrincipalGender',
        placeholder: '負責人性別',
        label: '負責人性別',
        type: 'select',
        options: [
          { label: '女', value: 'female' },
          { label: '男', value: 'male' }
        ]
      },
      {
        prop: 'headquarterPrincipalEmail',
        placeholder: '負責人電子郵件',
        label: '負責人電子郵件',
        type: 'email'
      },
      {
        prop: 'headquarterPrincipalPhone',
        placeholder: '負責人電話',
        label: '負責人電話'
      },
      {
        prop: 'headquarterContactId',
        placeholder: '取車人身分證字號',
        label: '取車人身分證字號'
      },
      {
        prop: 'headquarterContactName',
        placeholder: '取車人姓名',
        label: '取車人姓名'
      },
      {
        prop: 'headquarterContactBirthday',
        placeholder: '取車人生日',
        label: '取車人生日',
        type: 'date'
      },
      {
        prop: 'headquarterContactGender',
        placeholder: '取車人性別',
        label: '取車人性別',
        type: 'select',
        options: [
          { label: '女', value: 'female' },
          { label: '男', value: 'male' }
        ]
      },
      {
        prop: 'headquarterContactEmail',
        placeholder: '取車人電子郵件',
        label: '取車人電子郵件',
        type: 'email'
      },
      {
        prop: 'headquarterContactPhone',
        placeholder: '取車人電話',
        label: '取車人電話'
      }
    ],
    headquarter_edit: [
      {
        prop: 'headquarterTaxId',
        placeholder: '統一編號',
        label: '統一編號',
        type: 'number'
      },
      {
        prop: 'headquarterName',
        placeholder: '總部名稱',
        label: '總部名稱'
      },
      {
        prop: 'headquarterZip',
        placeholder: '郵遞區號',
        label: '郵遞區號'
      },
      {
        prop: 'headquarterAddress',
        placeholder: '地址',
        label: '地址'
      },
      {
        prop: 'headquarterPhone',
        placeholder: '電話',
        label: '電話'
      },
      {
        prop: 'headquarterEmail',
        placeholder: '電子郵件',
        label: '電子郵件',
        type: 'email'
      },
      {
        prop: 'headquarterPrincipalId',
        placeholder: '負責人身分證字號',
        label: '負責人身分證字號'
      },
      {
        prop: 'headquarterPrincipalName',
        placeholder: '負責人姓名',
        label: '負責人姓名'
      },
      {
        prop: 'headquarterPrincipalBirthday',
        placeholder: '負責人生日',
        label: '負責人生日',
        type: 'date'
      },
      {
        prop: 'headquarterPrincipalGender',
        placeholder: '負責人性別',
        label: '負責人性別',
        options: [
          { label: '女', value: 'female' },
          { label: '男', value: 'male' }
        ]
      },
      {
        prop: 'headquarterPrincipalEmail',
        placeholder: '負責人電子郵件',
        label: '負責人電子郵件',
        type: 'email'
      },
      {
        prop: 'headquarterPrincipalPhone',
        placeholder: '負責人電話',
        label: '負責人電話'
      },
      {
        prop: 'headquarterContactId',
        placeholder: '取車人身分證字號',
        label: '取車人身分證字號'
      },
      {
        prop: 'headquarterContactName',
        placeholder: '取車人姓名',
        label: '取車人姓名'
      },
      {
        prop: 'headquarterContactBirthday',
        placeholder: '取車人生日',
        label: '取車人生日',
        type: 'date'
      },
      {
        prop: 'headquarterContactGender',
        placeholder: '取車人性別',
        label: '取車人性別',
        options: [
          { label: '女', value: 'female' },
          { label: '男', value: 'male' }
        ]
      },
      {
        prop: 'headquarterContactEmail',
        placeholder: '取車人電子郵件',
        label: '取車人電子郵件',
        type: 'email'
      },
      {
        prop: 'headquarterContactPhone',
        placeholder: '取車人電話',
        label: '取車人電話'
      }
    ],
    manager_edit: [
      {
        prop: 'headquarterId',
        placeholder: '總部編號',
        label: '總部編號'
      },
      {
        prop: 'managerId',
        placeholder: '管理員編號',
        label: '管理員編號'
      }
    ],
    account_add: [
      {
        prop: 'headquarterId',
        label: '總部編號',
        placeholder: '總部編號',
        disabled: true,
        hidden: true,
        readonly: true
      },
      {
        prop: 'headquarterAccount',
        label: '帳號',
        placeholder: '帳號'
      },
      {
        prop: 'headquarterPassword',
        label: '密碼',
        placeholder: '密碼',
        type: 'password'
      },
      {
        prop: 'level',
        label: '權限等級',
        placeholder: '權限等級',
        type: 'select'
      }
    ],
    account_setting: [
      {
        prop: 'headquarterId',
        label: '總部編號',
        placeholder: '總部編號',
        disabled: true
      },
      {
        prop: 'headquarterAccount',
        label: '帳號',
        placeholder: '帳號',
        disabled: true
      },
      {
        prop: 'headquarterPassword',
        label: '密碼',
        placeholder: '密碼',
        type: 'password'
      },
      {
        prop: 'level',
        label: '權限等級',
        placeholder: '權限等級',
        type: 'select',
        disabled: true
      }
    ]
  }
}

// 管理員登入
export function LoginMangerReq(data) {
  return request({
    url: '/Manager/login/',
    data,
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getInfoReq() {
  return request({
    url: '/Manager/findOneByToken/',
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export const branchFuncs = {
  list: async function (inputData) {
    return request({
      url: '/Branch/list',
      method: 'post',
      data: {
        selectCols: inputData.selectCols ? inputData.selectCols : '',
        findCols: inputData.findCols ? inputData.findCols : ''
      },
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  }
}
export function getList(inputData) {
  return request({
    url: '/Branch/list',
    method: 'post',
    data: {
      selectCols: inputData.selectCols,
      sortCols: ''
    },
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}
export function edit(branchId, inputData) {
  return request({
    url: '/Branch/update/' + branchId,
    data: {
      branchName: inputData.branchName, //分店名稱
      branchAddress: inputData.branchAddress, //分店地址
      branchTaxId: inputData.branchTaxId, //分店統編
      branchManagerId: inputData.branchManagerId, //店長ID
      headquarterId: inputData.headquarterId, //總店ID
      branchStatus: inputData.branchStatus //分店狀態 0: disable, 1: enable
    },
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function add(inputData) {
  return request({
    url: '/Branch/create',
    data: {
      branchName: inputData.branchName, //分店名稱
      branchAddress: inputData.branchAddress, //分店地址
      branchTaxId: inputData.branchTaxId, //分店統編
      branchManagerId: inputData.branchManagerId, //店長ID
      headquarterId: inputData.headquarterId, //總店ID
      branchStatus: inputData.branchStatus //分店狀態 0: disable, 1: enable
    },
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function del(branchId) {
  return request({
    url: '/Branch/delete/' + branchId,
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getDetail(branchId) {
  return request({
    url: '/Branch/get/' + branchId,
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}
