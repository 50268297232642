import { LoginReq, LoginOutReq, getInfoReq } from '@/api/user'
import {
  setToken,
  removeToken,
  getToken,
  setForWorkOrder,
  setSwitchAccount,
  getSwitchAccount,
  setIsManager,
  setIsWarranty
} from '@/utils/auth'
import router, { asyncRoutes } from '@/router'
import { defineStore } from 'pinia'
import { usePermissionStore } from '@/store/permission'
import { useTagsViewStore } from '@/store/tagsView'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { useUserStore } from '@/store/user'
import { removeIsManager, removeIsWarranty } from '@/utils/auth'
import setting from '@/settings'
import { reservelFuncs } from '@/api/reserve'
import { useFunctionPermissionsStore } from '@/store/functionPermissions'; //檢查店家是否有電子簽署，滿意度調查


// 建立帳號
function createAccountObject(data) {
  return {
    headquarterId: data.headquarterId,
    employeeId: data.employeeId,
    employeeName: data.employeeName,
    avatar: data.employeeAvatar,
    token: data.token
  }
}
// 更新切換帳號資料
function updateSwitchAccount(existingAccounts, resData) {
  const branchId = resData.branchId
  const employeeId = resData.employeeId

  // 若沒有任何帳號資料，初始化一個空物件
  if (!existingAccounts) {
    existingAccounts = {}
  }

  // 同一台設備僅支援單一 branch 的帳號
  const branches = Object.keys(existingAccounts)
  if (branches.length && branches[0] !== branchId) {
    // 當 branch 不同，先清除原本的帳號資料
    existingAccounts = {}
  }

  // 若該 branch 尚未存在，則先建立
  if (!existingAccounts[branchId]) {
    existingAccounts[branchId] = {}
  }

  // 更新該 branch 下該 employee 的資料
  existingAccounts[branchId][employeeId] = createAccountObject(resData)
  return existingAccounts
}

const resetRouter = () => {
  const asyncRouterNameArr = asyncRoutes.map((mItem) => mItem.name)
  asyncRouterNameArr.forEach((name) => {
    if (router.hasRoute(name)) {
      router.removeRoute(name)
    }
  })
}

export const useAccountStore = defineStore('account', {
  state: () => {
    return {
      // headquarterAccount: '',
      // headquarterPassword : '',
      // managerName: '',
      // roles: [],
      userInfo: {}, //使用者資訊
      reserveSetting: {} //預約設定
    }
  },
  getters: {
    userDetails: (state) => state.userInfo?.data ?? {},
    isOpenReserve: (state) =>
      state.userInfo?.data?.is_reserve && state.reserveSetting?.is_open === '1',
    isAgentOpenReserve: (state) => state.reserveSetting?.is_open === '1',
    isSystemOpenReserve: (state) => state.userInfo?.data?.is_reserve
  },
  actions: {
    M_username(managerName) {
      this.$patch((state) => {
        state.managerName = managerName
      })
    },
    M_roles(roles) {
      console.log('M_roles', roles)
      this.$patch((state) => {
        state.roles = roles
      })
    },
    async login(data) {
      try {
        const res = await LoginReq(data)
        if (res.sysCode !== 200) throw res

        setToken(res.data.token)
        setForWorkOrder(
          JSON.stringify({
            headquarter_id: res.data.headquarterId,
            branch_id: res.data.branchId,
            employee_id: res.data.employeeId,
            avatar: res.data.employeeAvatar
          })
        )

        // 取得儲存在 local storage 的帳號資料
        let accounts = getSwitchAccount()
        // 更新帳號資料
        accounts = updateSwitchAccount(accounts, res.data)
        // 更新 local storage 中的帳號資料
        setSwitchAccount(accounts)

        return res
      } catch (error) {
        throw error
      }
    },
    create(data) {
      // console.log(data)
      return new Promise((resolve, reject) => {
        createAccount(data)
          .then((res) => {
            // console.log("res success")
            // console.log(res)
            if (res.sysCode === 200) {
              // console.log(res)
              //commit('SET_Token', res.data?.jwtToken)
              // setToken(res.data?.authToken)
              // console.log(res.data?.authToken)
              resolve(true)
            } else {
              reject(res)
            }
          })
          .catch((error) => {
            // console.log("res catch")
            reject(error)
          })
      })
    },
    // get user info
    getInfo() {
      return new Promise((resolve, reject) => {
        const userStore = useUserStore()
        getInfoReq()
          .then((res) => {
            // console.log('hello')
            const _isManagerData = JSON.stringify({
              branchManager: res.data.branchManager,
              headquarter_id: res.data.headquarterId, //總公司編號
              branch_id: res.data.branchId, //分公司編號
              employee_id: res.data.employeeId, //員工編號
              employee_name: res.data.employeeName //員工編號
            })
            setIsManager(_isManagerData)
            userStore.isManager = _isManagerData
            setForWorkOrder(
              JSON.stringify({
                headquarter_id: res.data.headquarterId, //總公司編號
                branch_id: res.data.branchId, //分公司編號
                employee_id: res.data.employeeId //員工編號
              })
            )
            setIsWarranty(JSON.stringify(res.data.is_warranty))
            this.userInfo = res
            console.log('userInfo', this.userInfo)

            this.getReserveSetting()

            resolve(res)
          })
          .catch((error) => {
            // console.log('error', error.sysCode)
            if (error.sysCode == 403) {
              ElMessage({
                message: '請重新登入',
                type: 'error',
                duration: 1000,
                onClose: function () {
                  userStore.resetState().then(() => {
                    router.push({ path: '/login' })
                  })
                }
              })
            }
            reject(error)
          })
      })
    },
    // user logout
    logout() {
      return new Promise((resolve, reject) => {
        // this.resetState()

        const functionPermissionsStore = useFunctionPermissionsStore();

        LoginOutReq()
          .then((response) => {
            // console.log(response)
            this.M_username('')
            this.M_roles([])
            removeToken() // must remove  token  first
            resetRouter() // reset the router
            removeIsManager() // remove if manager
            removeIsWarranty() // remove if warranty
            const permissionStore = usePermissionStore()
            permissionStore.M_isGetUserInfo(false)
            const tagsViewStore = useTagsViewStore()
            tagsViewStore.delAllViews()
            console.log('resetState')
            functionPermissionsStore.resetPermissions();
            resolve(response)
          })
          .catch((error) => {
            removeToken() // must remove  token  first
            console.log('error', error)
            reject(error)
          })

        // resolve(null)
      })
    },
    resetState() {
      return new Promise((resolve, reject) => {
        LoginOutReq()
          .then((response) => {
            // console.log(response)
            this.M_username('')
            this.M_roles([])
            removeToken() // must remove  token  first
            resetRouter() // reset the router
            const permissionStore = usePermissionStore()
            permissionStore.M_isGetUserInfo(false)
            const tagsViewStore = useTagsViewStore()
            tagsViewStore.delAllViews()
            resolve(null)
          })
          .catch((error) => {
            console.log('error', error)
            reject(error)
          })
      })
    },
    //取得預約設定
    async getReserveSetting() {
      return new Promise((resolve, reject) => {
        reservelFuncs
          .get()
          .then((res) => {
            this.reserveSetting = res.data
            // console.log('setting', this.reserveSetting)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
})
