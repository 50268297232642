import { createApp } from 'vue'
import App from './App.vue'

import { initializeApp } from 'firebase/app'
import { getDatabase, set, ref as sRef } from 'firebase/database'
// import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
// import '@andy922200/vue-tw-zip-code-selector/dist/vue-tw-zip-code-selector.css'
// import VueTwZipCodeSelector from '@andy922200/vue-tw-zip-code-selector'

const app = createApp(App)
// app.use(VueTwZipCodeSelector)

import router from './router'
const link = document.createElement('link')
link.href =
  'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;400;500;700;900&display=swap'
link.rel = 'stylesheet'
document.head.appendChild(link)

import '@/styles/index.scss' // global css
import '@/assets/css/base.scss' // global css
import '@/assets/css/element.scss' // global css

//import element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/index.scss'
app.use(ElementPlus)

//i18n
import { setupI18n } from '@/lang'
app.use(setupI18n)

//global mixin
// import elementMixin from '@/mixins/elementMixin'
// app.mixin(elementMixin)
// import commonMixin from '@/mixins/commonMixin'
// app.mixin(commonMixin)
// import routerMixin from '@/mixins/routerMixin'
// app.mixin(routerMixin)
//import axios req
// import axiosReq from '@/utils/axiosReq'
// app.config.globalProperties.$axiosReq = axiosReq
import 'material-symbols';
//svg-icon
//import svg-icon doc in  https://github.com/anncwb/vite-plugin-svg-icons/blob/main/README.zh_CN.md
import 'virtual:svg-icons-register'
import svgIcon from '@/icons/SvgIcon.vue'
app.component('SvgIcon', svgIcon)

//element svg icon
// import ElSvgIcon from '@/components/ElSvgIcon.vue'
// app.component('ElSvgIcon', ElSvgIcon)

//global mount moment-mini
// import $momentMini from 'moment-mini'
// app.config.globalProperties.$momentMini = $momentMini

//import global directive
import directive from '@/directives'
directive(app)

//import global directive
import components from '@/components'
components(app)

//import router  intercept
import './permission'

//error log  collection
import errorLog from '@/hooks/useErrorLog'
errorLog(app)

//axios cancel req
window.__axiosPromiseArr = []

//pinia
import { createPinia } from 'pinia'
app.use(createPinia())

//import theme
import './theme/index.scss'

//import unocss
import 'uno.css'

//#region import vxe-table
import VXETable from 'vxe-table'
import { createI18n } from 'vue-i18n'
import zhTW from 'vxe-table/lib/locale/lang/zh-TW'
import 'vxe-table/lib/style.css'

const messages = {
  zh_TW: {
    ...zhTW
  }
}
const i18n = createI18n({
  locale: 'zh_TW',
  messages
})

app.use(VXETable, {
  i18n: (key, args) => i18n.global.t(key, args)
})

app.use(router).mount('#app')
// #endregion

// #region vue-fire
const firebaseConfig = {
  // your application settings
  apiKey: 'AIzaSyD0toyKYhbSk50Bio5O6XsBaxhk1g7KpIs',
  authDomain: 'crypto-symbol-345815.firebaseapp.com',
  databaseURL: 'https://crypto-symbol-345815-default-rtdb.firebaseio.com',
  projectId: 'crypto-symbol-345815',
  storageBucket: 'crypto-symbol-345815.appspot.com',
  messagingSenderId: '296522975823',
  appId: '1:296522975823:web:76262500af21278836783a',
  measurementId: 'G-6S82HHMZPH'
}

const firebaseApp = initializeApp(firebaseConfig)
export const db = getDatabase(firebaseApp)

const errorToRealtime = async (err, instance, info) => {
  // 將資料傳入realtime
  console.log('錯誤捕捉main.js')
  // console.log(err)
  // console.log(instance)
  // console.log(info)
  const _host = import.meta.env.VITE_HOST
  const _currentRoute = router.currentRoute.value.fullPath
  const _obj = {
    host: _host,
    currentRoute: location.host + '/' + _currentRoute == '/' ? '' : _currentRoute,
    message: err.message ? err.message : '',
    stack: err.stack ? err.stack : '',
    // vue_instance : _instance,
    info: info
  }
  const getCurrentDate = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const currentDate = `${year}-${month}-${day}`
    return currentDate
  }
  const getCurrentTime = () => {
    const now = new Date()
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')
    return `${hours}:${minutes}:${seconds}`
  }
  const currentDate = getCurrentDate()
  const currentTime = getCurrentTime()
  const db = getDatabase()
  set(sRef(db, 'error/frontend/' + _host + '/' + currentDate + '/' + currentTime), _obj)
}
app.config.errorHandler = (err, instance, info) => {
  // 处理错误，例如：报告给一个服务
  console.error('errorHandler Error ===>', err)
  console.error('errorHandler instance ===>', instance)
  console.error('errorHandler info ===>', info)
  // info:https://cn.vuejs.org/error-reference/#runtime-errors
  errorToRealtime(err, instance, info)
}
// #endregion
