import request from '@/utils/axiosReq'
import { getForWorkOrder } from '@/utils/auth'

const loginStatus = JSON.parse(getForWorkOrder())
export const functionPermissionsFuncs = {
  
  getRolePermissions: function (inputData) {
    return request({
      url: '/FunctionPermissions/getRolePermissions',
      method: 'post',
      data: {
        level: inputData.level, 
        level_id: inputData.level_id 
      },
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  
}