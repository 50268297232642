import CryptoJS from 'crypto-js'

// #region 通用加解密
const WORK_ORDER_SECRET_KEY = 'work-order-by-foso' // 加密密鑰

export const workOrderEncryptParam = (param) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(param), WORK_ORDER_SECRET_KEY).toString()
  return encodeURIComponent(encrypted) // 確保 URL 安全
}

export const workOrderDecryptParam = (encryptedParam) => {
  const decryptedBytes = CryptoJS.AES.decrypt(
    decodeURIComponent(encryptedParam),
    WORK_ORDER_SECRET_KEY
  )
  return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8))
}
// #endregion

// #region 登入加解密
export const loginEncryptParam = (data) => {
  const { VITE_LOGIN_SECRET_KEY: loginSecretKey } = import.meta.env

  // 將 loginSecretKey 轉換為 256 bits 的 key
  const key = CryptoJS.enc.Hex.parse(CryptoJS.SHA256(loginSecretKey).toString())
  // 以 key 的前 16 bytes (32 個 hex 字元) 當作 IV
  const iv = CryptoJS.enc.Hex.parse(key.toString().substr(0, 32))

  // 加密 data，返回的加密結果為 base64 格式的字串
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })

  return encrypted.toString()
}
// #endregion
