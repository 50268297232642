import { defineStore } from 'pinia'
import { functionPermissionsFuncs } from '@/api/functionPermissions'
import { getForWorkOrder } from '@/utils/auth'

export const useFunctionPermissionsStore = defineStore('functionPermissions', {
  state: () => ({
    data: {} // 存放 function_name 對應的權限狀態
  }),
  
  getters: {
    hasPermission: (state) => (function_name) => {
      return !!state.data[function_name]; // 確保 function_name 為 true
    }
  },

  actions: {
    async getRolePermissions() {
      const loginStatus = JSON.parse(getForWorkOrder()); // 取得登入狀態
      try {
        const _data = {
          level: 'headquarter',
          level_id: loginStatus.headquarter_id
        };
    
        const _feedback = await functionPermissionsFuncs.getRolePermissions(_data);
        console.log('getRolePermissions', _feedback);
    
        // **只有在 `data: []` 時，初始化所有 function_name 為 false**
        const updatedPermissions = {};
    
        if (_feedback.data && _feedback.data.length > 0) {
          _feedback.data.forEach((item) => {
            if (item.level === _data.level && item.level_id === _data.level_id) {
              updatedPermissions[item.function_name] = true;
            }
          });
        } else {
          // **API 回傳空陣列，代表沒有任何權限，初始化全部 function_name**
          updatedPermissions.esignature = false;
          updatedPermissions.hq_repair = false;
          updatedPermissions.survey = false;
        }
    
        // **更新 Pinia state**
        this.data = updatedPermissions;
        console.log('Pinia permissions 更新:', this.data);
        
      } catch (error) {
        console.log('error', error);
      }
    }
    
    ,
    async checkPermission(function_name) {
      // 如果 state 內部已經初始化過（有值，不論是 true 或 false），直接回傳
      if (this.data && Object.keys(this.data).length > 0) {
        return this.hasPermission(function_name);
      }
    
      // 如果 state 為 null 或空物件，代表還沒取得權限，則呼叫 API
      await this.getRolePermissions();
    
      return this.hasPermission(function_name);
    },
    
    resetPermissions() {
      this.data = {}; // 清空所有權限
      console.log('Pinia permissions 已清空');
    }
  }
});
