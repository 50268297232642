<template>
  <el-affix :offset="0">
    <div class="navbar rowBC reset-el-dropdown">
      <div class="rowSC">
        <!-- <Logo /> -->
        <router-link class="px-3" to="/">
          <img src="/assets/images/logo.svg" alt="" width="50" />
        </router-link>
        <!-- <hamburger
          v-if="settings.showHamburger"
          :is-active="opened"
          class="hamburger-container"
          @toggleClick="toggleSideBar"
        />
        <breadcrumb class="breadcrumb-container" /> -->
      </div>
      <!--nav title-->
      <div v-if="settings.showNavbarTitle" class="heardCenterTitle">
        {{ settings.showNavbarTitle }}
      </div>
      <!-- search bar -->
      <div class="md:w-1/2 hidden md:inline-block md:pl-90px">
        <DashboardSearchInput v-if="$route.path !== '/dashboard'" class="w-full" />
      </div>

      <div v-if="settings.ShowDropDown" class="right-menu rowSC">
        <template v-if="empLogined">
          <el-dropdown trigger="click" @visible-change="BellClick">
            <span class="el-dropdown-link me-5">
              <template v-if="gotAlert">
                <div style="position: relative" :class="{ 'shake-bell': isShaking }">
                  <div
                    style="
                      position: absolute;
                      top: 0;
                      right: 0;
                      width: 5px;
                      height: 5px;
                      background-color: red;
                      border-radius: 50%;
                    "
                  ></div>
                  <el-icon size="26"><BellFilled /></el-icon>
                </div>
              </template>
              <template v-else>
                <el-icon size="26"><BellFilled /></el-icon>
              </template>
              <!-- <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon> -->
            </span>
            <template #dropdown>
              <el-dropdown-menu v-if="saMessages.length > 0">
                <el-scrollbar height="280" class="relative">
                  <template v-for="(messages, idx) in saMessages">
                    <div v-if="messages.status == 'read' || !messages.status">
                      <!-- <el-dropdown-item>
                        <el-row justify="center" align="middle" style="width:240px" :gutter="0">
                          <el-col :span="20">
                            <div style="display:block;width:100%;position:relative"  @click.prevent="messageControl('read',messages)">
                              <div class="my-0 me-5" v-if="!messages.status">
                                <b style="display:block">
                                  <div style="width: 5px; height: 5px; background-color: red; border-radius: 50%;display: inline-block;margin-right: 6px;vertical-align: middle;"></div>{{ messages.title.length > 20 ? messages.title.slice(0, 20) + '...' : messages.title }}</b>
                              </div>
                              <div class="my-0 me-5" v-else>
                                {{ messages.title.length > 20 ? messages.title.slice(0, 20) + '...' : messages.title }}
                              </div>
                              <div style="display:block">
                                <p class="my-0"><span style="cursor:pointer">{{ messages.content.length > 20 ? messages.content.slice(0, 20) + '...' : messages.content }}</span></p>
                                <p class="my-0" style="color:#909399;font-size:11px">{{ messages.created_at }}</p>
                              </div>
                            </div>
                          </el-col>
                          <el-col :span="2" style="text-align:right">
                            <el-icon size="14" @click.prevent="messageControl('hide',messages)"><CloseBold /></el-icon>
                          </el-col>
                        </el-row>
                      </el-dropdown-item> -->

                      <el-dropdown-item divided @click.prevent="messageControl('read', messages)">
                        <div class="block relative w-full">
                          <el-icon
                            size="14"
                            @click.stop="messageControl('hide', messages)"
                            style="position: absolute; right: -10px; top: 0; z-index: 3"
                            ><CloseBold
                          /></el-icon>
                          <div class="my-0 me-5" v-if="!messages.status">
                            <b style="display: block">
                              <div
                                style="
                                  width: 5px;
                                  height: 5px;
                                  background-color: red;
                                  border-radius: 50%;
                                  display: inline-block;
                                  margin-right: 6px;
                                  vertical-align: middle;
                                "
                              ></div>
                              {{
                                messages.title.length > 20
                                  ? messages.title.slice(0, 20) + '...'
                                  : messages.title
                              }}</b
                            >
                          </div>
                          <div class="my-0 me-5" v-else>
                            {{
                              messages.title.length > 20
                                ? messages.title.slice(0, 20) + '...'
                                : messages.title
                            }}
                          </div>
                          <div style="display: block">
                            <p class="my-0">
                              <span style="cursor: pointer">
                                {{
                                  stripHtml(messages.content).length > 20
                                    ? stripHtml(messages.content).slice(0, 20) + '...'
                                    : stripHtml(messages.content)
                                }}
                              </span>
                            </p>
                            <p class="my-0" style="color: #909399; font-size: 11px">
                              {{ messages.created_at }}
                            </p>
                          </div>
                        </div>
                      </el-dropdown-item>
                    </div>
                    <!-- <el-dropdown-item  v-for="(content, time) in reverseObject(messages)"  @click.prevent="messageControl(content)">
                      <div style="display:block">
                        <b style="display:block" class="my-0">{{ content.title.length > 25 ? content.title.slice(0, 25) + '...' : content.title }}</b>
                        <div style="display:block">
                          <p class="my-0"><span style="cursor:pointer">{{ content.content.length > 20 ? content.content.slice(0, 20) + '...' : content.content }}</span></p>
                          <p class="my-0" style="color:#909399;font-size:11px">{{ time }}</p>
                        </div>
                      </div>
                    </el-dropdown-item> -->
                  </template>
                </el-scrollbar>
              </el-dropdown-menu>
              <el-dropdown-menu v-else>
                <el-dropdown-item>沒有通知</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dropdown trigger="click" size="medium">
            <div class="avatar-wrapper flex justify-center items-center mb-1">
              <!-- <img
                :src="empLogined.employeeAvatar"
                class="user-avatar mr-2"
              /> -->
              <el-avatar
                :style="
                  'background: url(' +
                  logoBg +
                  ');background-size: cover;border: 1px solid #dcdfe6;filter: grayscale(0.20);background-color: transparent;'
                "
                :size="40"
                class="me-1"
                fit="cover"
                :src="empLogined.employeeAvatar"
              />

              <span class="ms-0">{{ empLogined.employeeName }}({{ empLogined.employeeId }})</span>
              <CaretBottom style="width: 1.2em; height: 1.2em; margin-left: 4px" class="w-3 h-3" />
            </div>
            <template #dropdown v-if="listCenter['other_emp'] !== null">
              <el-dropdown-menu>
                <template v-for="item in listCenter['other_emp']">
                  <template v-if="item.employeeId == empLogined.employeeId"></template>
                  <template v-else>
                    <div
                      class="avatar-wrapper flex items-center pl-2 pr-3"
                      @click.prevent="changeAccount(item)"
                    >
                      <el-avatar
                        :style="
                          'background: url(' +
                          logoBg +
                          ');background-size: cover;border: 1px solid #dcdfe6;filter: grayscale(0.20);background-color: transparent;'
                        "
                        :size="40"
                        class="me-1"
                        fit="cover"
                        :src="item.avatar"
                      />
                      <span>{{ item.employeeName }}</span>
                    </div>
                  </template>
                </template>
                <!--<el-dropdown-item>修改密碼</el-dropdown-item>-->
                <!-- divided -->
                <li role="separator" class="el-dropdown-menu__item--divided" />
                <el-dropdown-item @click="loginOut">
                  <div w-full text-center>登出</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
        <template v-else>
          <div @click.prevent="loginOut">登出</div>
        </template>
      </div>
    </div>

    <ChatRoom :room-data="roomData" ref="roomRef" />
    <el-dialog v-model="alertDialogVisible" title="公告" width="700" align-center>
      <p style="font-size: 18px" class="mb-0">{{ saMessagesSelected.title }}</p>
      <div class="text-end">
        <small class="mb-0">{{ saMessagesSelected.created_at }}</small>
      </div>
      <hr />
      <el-scrollbar height="300px">
        <div
          id="content-saM"
          class="mt-3 mb-5"
          v-html="convertLinksToHTML(saMessagesSelected.content)"
        ></div>
      </el-scrollbar>
      <!-- <div v-if="showList">
        <template v-for="(messages, date) in saMessages">
          <el-card shadow="never">
            <template #header>{{ date }}</template>
            <table>
              <template v-for="(content, time) in reverseObject(messages)">
                <tr>
                  <td @click.prevent="messageControl(content)"><b style="cursor:pointer">{{ time }}</b></td>
                  <td @click.prevent="messageControl(content)"><b style="cursor:pointer" class="pl-2">{{ content.title }}</b></td>
                </tr>
                <tr>
                  <td @click.prevent="messageControl(content)"></td>
                  <td @click.prevent="messageControl(content)">
                    <span class="pl-2" style="cursor:pointer">{{ content.content.length > 30 ? content.content.slice(0, 30) + '...' : content.content }}</span>
                  </td>
                </tr>
              </template>
            </table>
          </el-card>
        </template>
      </div>
      <div v-else>
        <p style="font-size:18px"><span @click.prevent="showList = true" style="vertical-align: bottom;cursor:pointer"><el-icon size="24"><ArrowLeftBold /></el-icon></span>{{saMessagesSelected.title}}</p>
        <p>{{saMessagesSelected.content}}</p>
      </div> -->
    </el-dialog>

    <el-dialog
      v-model="dialogPassword"
      :title="'請輸入' + _targetAccount?.employeeId + '的密碼'"
      width="500"
    >
      <el-form :model="switchForm" @submit.prevent="loginAccount">
        <el-form-item label="密碼">
          <el-input v-model="loginPassword" type="password" show-password autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogPassword = false">取消</el-button>
          <el-button type="primary" @click.prevent="loginAccount"> 登入 </el-button>
        </div>
      </template>
    </el-dialog>
  </el-affix>
</template>

<script setup>
// import SizeSelect from '@/components/SizeSelect/index.vue'
// import LangSelect from '@/components/LangSelect/index.vue'
// import ScreenFull from '@/components/ScreenFull/index.vue'
// import ThemeSelect from '@/components/ThemeSelect/index.vue'
// import ScreenLock from '@/components/ScreenLock/index.vue'
import { CaretBottom, BellFilled, ArrowLeftBold, Close, CloseBold } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'

import {
  getDatabase,
  ref as storageRef,
  set,
  push,
  onValue,
  update,
  get,
  orderByChild,
  equalTo,
  onChildChanged,
  onChildAdded
} from 'firebase/database'
import { v4 as uuidv4 } from 'uuid'
import ChatRoom from '@/views/transfer/components/chatRoom.vue'

import Breadcrumb from './Breadcrumb'
import Hamburger from './Hamburger'

import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
// import { useManagerStore } from '@/store/manager'
import { useAccountStore } from '@/store/branch'
import { getList } from '@/api/employee'
import { setSwitchAccount, getSwitchAccount, getSaMessages, setSaMessages } from '@/utils/auth'
import axios from 'axios'
import { setToken, setForWorkOrder, getForWorkOrder } from '@/utils/auth'
import { ref } from 'vue'
import { loginEncryptParam } from '@/utils/encrypt'

import DashboardSearchInput from '@/components/Dashboard/Search/DashboardSearchInput.vue'

const logoBg =
  'https://firebasestorage.googleapis.com/v0/b/crypto-symbol-345815.appspot.com/o/avatar%2Flogo800.png?alt=media&token=660c6e46-8635-4c3d-97d7-e23ff931548a'

const gotAlert = ref(false) //是否有通知
const alertDialogVisible = ref(false) //是否顯示通知對話框

const showList = ref(true) //是否顯示div
const route = useRoute()
const router = useRouter()

const roomRef = ref(null)

const settings = computed(() => {
  return appStore.settings
})
const opened = computed(() => {
  return appStore.sidebar.opened
})
const appStore = useAppStore()
const toggleSideBar = () => {
  appStore.M_toggleSideBar()
}
const userStore = useAccountStore()

const stripHtml = (html) => {
  // 使用正則移除 HTML 標籤
  return html.replace(/<\/?[^>]+(>|$)/g, '').replace(/\n/g, '')
}
/*
 * 退出登录
 * */
const loginOut = (type = null) => {
  const msg = ref('登出成功')
  if (type == 'haveTo') {
    msg.value = '請重新登入'
  }
  userStore
    .logout()
    .then(() => {
      ElMessage({ message: msg.value, type: 'success' })
      // router.push(`/login?redirect=/`)
      location.reload();
    })
    .catch((error) => {
      console.log('loginOut error', error)
      // ElMessage({ message: error.data, type: error.sysMsg })
      // router.push(`/login?redirect=/`)
      // reject(error)
    })
}
const empLogined = ref(null)
const gotInfo = () => {
  userStore
    .getInfo()
    .then((data) => {
      // console.log('dsd')
      // console.log(data)
      empLogined.value = data.data
      // goGetList()
    })
    .catch((error) => {
      loginOut('haveTo')
      throw error
      // console.log('error', error)
      // ElMessage({ message: error.data , type: error.sysMsg })
      // reject(error)
    })
}
gotInfo()

const listCenter = ref({
  other_emp: []
})
// const goGetList = () => {
//   getList().then((data) => {
//     // empLogined.value = data.data
//     listCenter.value['other_emp'] = data.data;
//     // console.log(listCenter.value['other_emp'])
//   }).catch((error) => {
//     console.log('error', error)
//     ElMessage({ message: error.data , type: error.sysMsg })
//     reject(error)
//   })
// }

const _accounts = getSwitchAccount() //取得帳號列表
const _localBranchId = Object.getOwnPropertyNames(_accounts)[0] //取得其他登入人的branchId;
const _listObj = Object.keys(_accounts[_localBranchId]) //key列表

if (_accounts && _listObj) {
  Object.values(_listObj).forEach((item) => {
    // 迴圈localStorge的登入account列表
    listCenter.value['other_emp'].push(_accounts[_localBranchId][item])
  })
}
const loginPassword = ref('') //重新登入的密碼
const dialogPassword = ref(false) //重新登入的對話筐
const _targetAccount = ref() //要去的account
const changeAccount = (_empData) => {
  console.log('changeAccount _empData ===>', _empData)
  _targetAccount.value = _empData
  dialogPassword.value = true

  // _targetAccount.value = _empData
  // const _gotToken = _accounts[_localBranchId][_empData['employeeId']]['token']
  // let _url = __DEV__ ? VITE_APP_DEV_API_URL : VITE_APP_PROD_API_URL
  // console.log('_gotToken', _gotToken)
  // axios({
  //   method: 'post',
  //   url: _url + '/Employee/findOneByToken/',
  //   headers: {
  //     AUTHORIZE_TOKEN: _gotToken,
  //     Authorization: _gotToken
  //   }
  // })
  //   .then((response) => {
  //     console.log(response)
  //     if (response.data.sysCode == 200) {
  //       // 沒過期，複寫登入token
  //       const _nowData = _accounts[_localBranchId][_empData['employeeId']]
  //       setForWorkOrder(
  //         JSON.stringify({
  //           headquarter_id: _nowData.headquarterId, //總公司編號
  //           branch_id: _localBranchId, //分公司編號
  //           employee_id: _nowData.employeeId //員工編號
  //         })
  //       )
  //       setToken(_nowData.token)
  //       if (router.currentRoute.value.path == '/dashboard') {
  //         location.reload()
  //       } else {
  //         window.location.href = '/dashboard'
  //         // router.replace(`/dashboard`);
  //         // location.reload()
  //       }
  //     } else {
  //       dialogPassword.value = true
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //     dialogPassword.value = true
  //   })
}

const switchForm = ref({
  password: ''
})

const loginAccount = async () => {
  try {
    const res = await userStore.login({
      employeeId: _targetAccount.value.employeeId,
      employeePassword: loginEncryptParam(loginPassword.value)
    })

    if (res.sysCode === 200) {
      window.location.href = '/dashboard'
      ElMessage({ message: '切換成功', type: 'success' })
    } else {
      ElMessage({ message: res.sysMsg, type: 'warning' })
    }
  } catch (err) {
    console.error('loginAccount error ===> ', err)
  }
}

// #region watch messages
const db = getDatabase()
const userInfo = computed(() => userStore.userDetails)

const roomData = ref({})
const notificationInstanceRoom = ref(null)
const closeNotificationRoom = () => {
  // 使用 notificationInstanceRoom 實例的 close 方法來手動關閉通知
  notificationInstanceRoom.value.close()
}

async function handleMessage(chatData) {
  if (roomRef.value.chatDrawer) return
  console.log('chatData.sender', chatData)

  if (chatData.order.create) return

  notificationInstanceRoom.value = ElNotification({
    type: 'success',
    title: '您有新的訊息',
    message: h('i', { style: 'color: teal' }, '點擊查看'),
    duration: 0,
    onClick: (e) => {
      roomRef.value.showRoom()
      roomData.value = chatData
      closeNotificationRoom()
    }
  })
}

//當有人發送訊息時
async function getChatData() {
  const { branchId: uid } = userInfo.value

  const chatRef = storageRef(db, `chatRooms/${uid}`)

  onChildChanged(
    chatRef,
    async (childSnapshot, previousChildKey) => {
      const changedData = await childSnapshot.val()
      const key = childSnapshot.key
      // console.log(`節點 ${key} 的數據已更改：`, changedData);

      const [firstEntryKey, firstEntryValue] = Object.entries(changedData)[0]
      // console.log("firstEntryValue", firstEntryValue);
      await nextTick()

      const messageKeys = Object.keys(firstEntryValue.messages)
      const lastMessageKey = messageKeys[messageKeys.length - 1]
      const lastMessageData = firstEntryValue.messages[lastMessageKey]
      console.log('lastMessageData', lastMessageData)
      // const lastMessageIsSystem = () => {
      //   return lastMessageData.sender === "system";
      // };

      const formatData = {
        store: {
          branch_id: uid,
          material_id: firstEntryValue.productId,
          material_name: firstEntryValue.material_name
        },
        user: {
          branchId: firstEntryValue.uid,
          employeeName: firstEntryValue.employeeName
        },
        order: {
          create: lastMessageData.sender === 'system'
        }
      }

      console.log('Before if condition', lastMessageData.sender === 'system')
      // 在這裡執行你的應用邏輯，例如更新界面或其他操作
      if (lastMessageData.sender === 'system') {
        // 如果是系統訊息
      } else {
        console.log('lastMessageData', lastMessageData)
        handleMessage(formatData)
      }
    },
    (error) => {
      console.error('監聽失敗：', error)
    }
  )
}
watch(
  () => userInfo.value.branchId,
  async (newValue, oldValue) => {
    if (newValue) {
      await getChatData()
    }
  }
)
// #endregion

// #region watch notify
const notificationInstanceNotify = ref(null)
const closeNotificationNotify = () => {
  // 使用 notificationInstanceNotify 實例的 close 方法來手動關閉通知
  notificationInstanceNotify.value.close()
}

async function handleNotify(lastMessageKey) {
  roomRef.value.chatDrawer = false
  notificationInstanceNotify.value = ElNotification({
    title: '您有新的交易紀錄',
    type: 'info',
    position: 'top-left',
    message: h('i', { style: 'color: teal' }, `訂單編號：${lastMessageKey}`),
    duration: 0,
    onClick: () => {
      router.push({ path: '/transfer/records' })
      closeNotificationNotify()
    }
  })
}

// 當有通知時
const initNotify = ref(0)
function getNotifyData() {
  const notifyRef = storageRef(db, `notify/${userInfo.value.branchId}`)

  onValue(
    notifyRef,
    (snapshot) => {
      const data = snapshot.val()
      if (!data) return
      const messageKeys = Object.keys(data)
      const lastMessageKey = messageKeys[messageKeys.length - 1]
      const lastMessageData = data[lastMessageKey]

      // 在這裡處理你的通知數據
      if (initNotify.value !== 0) {
        handleNotify(lastMessageKey)
      }
      initNotify.value++
    },
    (error) => {
      console.error('監聽失敗：', error)
    }
  )
}

watch(
  () => userInfo.value.branchId,
  async (newValue, oldValue) => {
    if (newValue) {
      await getNotifyData()
    }
  }
)

// #endregion

const loginStatus = JSON.parse(getForWorkOrder())
const _headquarterId = String(loginStatus.headquarter_id)
const _branchId = String(loginStatus.branch_id)
const sanitizePath = (path) => {
  return path.replace(/[.#$[\]]/g, '_')
}
// sa的訊息來了
const _selfRef =
  'sa_send' + '/' + _headquarterId + '/' + _branchId + '/' + sanitizePath(loginStatus.employee_id)
console.log('_selfRef', _selfRef)
const saMessagesRef = storageRef(db, _selfRef) // 假設 'messages' 是存儲訊息的節點
const saMessages = ref({})

// const reverseObject = (obj) => {
//     const reversedObject = {};
//     const keys = Object.keys(obj).reverse();  // 獲取對象鍵的陣列並倒序

//     keys.forEach(key => {
//         reversedObject[key] = obj[key];  // 將倒序的鍵和對應的值加入新對象
//     });
//     console.log('reversedObject')
//     console.log(reversedObject)
//     return reversedObject;
// }
const processNotifications = (_obj, user) => {
  // 獲取對象鍵的陣列並倒序
  const keys = Object.keys(_obj).reverse()
  // 根據倒序的鍵，獲取對應的值並形成新的陣列
  const reversedValues = keys.map((key) => _obj[key])

  // 使用 filterNotifications 函數來篩選通知
  const filteredNotifications = reversedValues.filter((item) => {
    if (!item.status || item.status == 'read') {
      return item
    }
  })
  // console.log('filteredNotifications')
  // console.log(filteredNotifications)
  return filteredNotifications
}
const convertLinksToHTML = (inputString) => {
  // 正則表達式匹配大部分網址
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  // 使用 replace 方法將找到的網址轉換成 a 標籤
  return inputString.replace(urlRegex, function (url) {
    return `<a href="${url}" target="_blank">${url}</a>`
  })
}
const needNotify = () => {
  const firstMsg = saMessages.value[0]
  if (firstMsg && firstMsg.type && firstMsg.type == 'reserve') {
    // console.log("有新的w預w約")
    // console.log(firstMsg)
    ElNotification({
      title: '您有一筆新的預約',
      message: firstMsg.title + ' ' + firstMsg.content,
      type: 'warning',
      duration: 0,
      position: 'bottom-right',
      customClass: 'notify-reserve',
      onClick: () => {
        router.push({ path: '/reserve/list', query: { reserve_id: firstMsg.reserve_id } })
        ElNotification.closeAll()
      }
    })
  }
}

// 訂閱數據更新
onValue(
  saMessagesRef,
  (snapshot) => {
    let data = snapshot.val()
    data = data ? data : []
    saMessages.value = processNotifications(data, loginStatus) //將數據轉換成陣列
    // 如果saMessages的長度大於，則顯示通知
    const _saMessagesLength = saMessages.value.length //saMessages的長度
    const localMessagesLength = getSaMessages() //本地的saMessages長度
    if (!localMessagesLength) {
      setSaMessages(0)
    }
    // console.log(_saMessagesLength, localMessagesLength)
    if (_saMessagesLength != localMessagesLength) {
      console.log('有新的通知')
      gotAlert.value = true
      // 檢查最新一筆，如果是預約維修要跳ElNotification
      needNotify()
      console.log(saMessages.value)
    } else {
      // console.log('沒新的通知')
      gotAlert.value = false
    }
    setSaMessages(_saMessagesLength)
  },
  (error) => {
    console.error('監聽失敗：', error)
  }
)
const goShowAlerts = () => {
  gotAlert.value = false
  alertDialogVisible.value = true
}
const saMessagesSelected = ref({
  title: '',
  content: '',
  created_at: ''
})
const messageControl = (type, content) => {
  const _new = storageRef(db, _selfRef + '/' + content.sendId + '/status')
  if (type == 'read') {
    set(_new, 'read')
    if (content.type == 'reserve') {
      console.log(content.reserve_id)
      router.push({
        path: '/reserve/list',
        query: {
          reserve_id: content.reserve_id,
          timestamp: Date.now() // 添加當前時間戳
        }
      })
      ElNotification.closeAll()
    } else {
      alertDialogVisible.value = true
      saMessagesSelected.value = content
    }
    // gotAlert.value = false;
    return
  }
  if (type == 'hide') {
    ElMessageBox.confirm('確定刪除本公告？', '', {
      confirmButtonText: '刪除',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        // 点击了确定按钮
        set(_new, 'hide')
      })
      .catch(() => {
        // 点击了取消按钮
      })
    return
  }
}
const BellClick = () => {
  gotAlert.value = false
}

// #region shake bell
const isShaking = ref(false)

let intervalId

const startShaking = () => {
  isShaking.value = true
  setTimeout(() => {
    isShaking.value = false
  }, 500) // 晃動時間0.5秒
}

onMounted(() => {
  intervalId = setInterval(startShaking, 3000) // 每2秒開始一次晃動
})

onUnmounted(() => {
  clearInterval(intervalId)
})
// #endregion
</script>

<style lang="scss">
.notify-reserve {
  background-color: rgb(90, 156, 248) !important;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  border: none !important;
  width: 450px !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.62) !important;
  .el-icon {
    color: white !important;
  }
  .el-notification__title {
    color: white !important;
    font-size: 1rem !important;
  }
  .el-notification__content p {
    color: white !important;
    font-size: 0.9rem !important;
  }
}
</style>
<style lang="scss" scoped>
.navbar {
  height: var(--nav-bar-height);
  // overflow: hidden;
  position: relative;
  background: var(--nav-bar-background);
  box-shadow: var(--nav-bar-box-shadow);
}

//logo
.avatar-wrapper {
  margin-top: 5px;
  position: relative;
  cursor: pointer;

  .user-avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 10px;
  }

  .el-icon-caret-bottom {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: 25px;
    font-size: 12px;
  }
}

//center-title
.heardCenterTitle {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 46%;
  font-weight: 600;
  font-size: 20px;
  transform: translate(-50%, -50%);
}

//drop-down
.right-menu {
  cursor: pointer;
  margin-right: 40px;
  background-color: var(--nav-bar-right-menu-background);
}
</style>
<style>
#content-saM a {
  color: #e6a23c;
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
}

.shake-bell {
  display: inline-block;
  animation: shake 0.5s ease-in-out;
}
</style>
